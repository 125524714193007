.aai__container {
    height: 200px;
    width: 330px;
}

.aai__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.aai__logo-line {
    position: absolute;
    top: 41%;
    left: 0;
}

.aai__logo-rectangle-exterieur {
    position: absolute;
    left: 24%;
    top: 3%;
}

.aai__logo-animated-circles {
    position: absolute;
    left: 8%;
    top: -25%;
}

.aai__logo-main-rectangle {
    position: absolute;
    left: 20%;
    top: -4%;
}

.aai__logo-app-icon {
    position: absolute;
    left: 28%;
    top: 9%;
}

img.logo-line {
    width: 330px;
}

img.logo-rectangle-exterieur {
    width: 175px;
}

img.logo-main-rectangle {
    width: 200px;
    transform: scale(2);
}

img.logo-animated-circles {
    transform: scale(2);
}

img.app-icon {
    opacity: 0;
}