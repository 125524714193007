.section__hero__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    height: 85vh;
    padding: 0 0 20px 0;
    margin: 0;
    max-width: 100%;
    background: url('/public/images/background-fog.jpg');
    background-size: cover;
}

.section__hero__container__temp__background__img {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section__hero__aai__wrapper {
    margin-top: 5vh;
}

.section__hero__logo__wrapper img {
    position:relative;
    max-width: 450px;
    animation: fadeIn 1s;
}

.section__hero__stores {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
}

.section__hero__stores img {
    width: 130px;
}



.section__hero__video__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    /* background: linear-gradient(180deg, rgba(87, 87, 87, 0) 0%, rgba(0, 0, 0, 0.507) 100%); */
}

.section__hero__container p {
    text-transform: uppercase;
    font-weight: 800;
}

.section__hero__scrolldown {
    height: fit-content;
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

/* Small devices (phones) */
@media (max-width: 576px) {
    .section__hero__container {
        gap: inherit;
        height: inherit;
        min-height: 70vh;
        padding: 20px 0;
    }

    .section__hero__logo__wrapper img {
        max-width: 300px;
    }

    .section__hero__aai__wrapper {
        transform: scale(0.8);
        margin-top: 20px;
    }

    .section__hero__stores {
        width: 100%;
        gap: 0;
    }

    .section__hero__stores a {
        width: 33%;
    }

    .section__hero__stores img, .section__hero__stores svg {
        width: 115px;
        image-rendering: auto;
        image-rendering: crisp-edges;
        image-rendering: pixelated;
        /* Safari seems to support, but seems deprecated and does the same thing as the others. */
        image-rendering: -webkit-optimize-contrast;
    }

    .section__hero__container p {
        text-transform: inherit;
        width: 90%;
    }

    .section__hero__scrolldown {
        height: fit-content;
        padding: 0;
        margin: 0 0 3vh 0;
        position: relative;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}