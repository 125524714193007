.navbar__container {
    height: 80px;
    background: url('/public/images/navbar-background.png');
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 999;
}

.navbar__wrapper {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 25%;
}

.navbar__container p {
    font-weight: 600;
}

.navbar__container a {
    text-decoration: none;
}

.navbar__logo img {
    width: 45px;
    outline: none;
    cursor: pointer;
}

.navbar__menu__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5vh;
    height: 100%;
}

.navbar__menu__item { 
    height: 100%;
    border-bottom: 5px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.navbar__social__icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:20px;
}

.navbar__social__icons img {
    filter: brightness(0) invert(1);
    width: 20px;
}

.navbar__social__icons a {
    width: 30px;
}

.navbar__menu__item:hover {
    border-bottom-color: aqua;
}

.menu__icon {
    display: none;
}

/* Small devices (phones) */
@media (max-width: 576px) {
    .menu__icon {
        display: block;
        color: #fff;
        font-size: 30px;
    }

    .navbar__menu__item:hover {
        border-bottom-color: transparent;
    }

    .navbar__social__icons {
        display: none;
    }

    .navbar__menu__wrapper {
        display: none;
        opacity: 0;
        transition: all .6s ease-in-out;
    }

    .navbar__wrapper {
        justify-content: space-between;
    }

    .navbar__menu__wrapper.active {
        position: fixed;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all .6s ease-in-out;
        opacity: 1;
        display: flex;
        gap: 30px;
        padding: 30px 0;
        width: 100%;
        height: fit-content;
        top: 80px;
        left: 0;
        background-color: rgba(26, 14, 136, 0.63);
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}