.loader {
    border-color: transparent;
    border-top: 5px #f7bb38;
    border-bottom: 5px #f7bb38;
    border-style: solid;
    border-width: 5px;
    border-radius: 50%;
    width: 90px;
    background-color: transparent;
    height: 90px;
    animation: spin 1s linear infinite;
    transition: all .2s ease-in-out;
}
  
.loader__container {
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
  