.main__button__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('/public/images/Main-Button.png');
    cursor: pointer;
}

.main__button__container:hover {
    transform: translateY(-3px)!important;
    transition: .3s ease-in-out;
    box-shadow: 0 0 20px 10px rgba(238, 151, 11, 0.192);
}

.main__button__container p {
    color: #fff;
    padding: 0;
    line-height: 2rem;
    font-family: 'Oswald', sans-serif;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 25px;
    width: 100%;
    text-shadow: 2px 2px 0px #818181;
}

.main__button__container svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: rgb(255, 248, 211);
    transition: 1s ease-in-out;
}

.main__button__container span {
    color: white;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400;
}