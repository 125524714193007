.homepage__container {
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
    gap: 0;
    overflow: hidden;
    margin-top: 80px;
}

.sections__news__and__what__is__cb {
    position: relative;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background: url('/public/images/background-2.png');
}

.particles__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}


/* Small devices (phones) */
@media (max-width: 576px) {

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}