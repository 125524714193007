.launch__email__container {
    width: 100%;
    position: relative;
    display: flex;
    margin: 80px 0 0 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.launch__email__wrapper {
    width: 600px;
    max-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.launch__email__section1 {
    width: 100%;
    background: url('/public/images/launch-email/background-section-1.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 60px;
    padding: 157.5% 0 0 0;
    height: 0;
}

.launch__email__section1__content {
    position: absolute;
    top: 5%;
}

.launch__email__section2 {
    background: url('/public/images/launch-email/background-section-2.png');
    background-repeat: no-repeat;
    background-size: 100%;
    display: block;
    margin-top: -100px;
    position: relative;
    width: 100%;
    padding-top: 25%;
}

.launch__email__section2__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0 0 20px 0;
}

.launch__email__section2__image {
    position: relative;
    width: 90%;
}

.launch__email__section2__image__text {
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
    text-align: left;
}

.launch__email__section2 img {
    max-width: 100%;
}

.launch__email__section1__cta {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3vh;
    margin-top: 85%;
    flex-direction: column;
}

.launch__email__cb__logo {
    width: 60%;
    position:relative;
}

.launch__email__background__1 {
    width: 100%;
    position: absolute;
}

.launch__email__section1__text {
    font-weight: 600;
    font-size: 18px;
}

.launch__email__store__ctas {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap:10px
}

.launch__email__store__ctas img {
    width: 20%;
}

.launch__email__section3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5vh;
    margin-top: 8vh;
    padding: 0 0 20px 0;
}

.launch__email__section3__3__icons {
    width: 80%;
}

.launch__email__section3 p {
    font-weight: 800;
    font-size: 16px;
    max-width: 80%;
}

.launch__email__section3 img {
    max-width: 80%;
}



/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
    .launch__email__section1  {
        gap: 30px;
        padding-top: 194.5%;
    }

    .launch__email__section1__cta {
        margin-top: 64%;
    }

    .launch__email__section2 {
        margin-top: -215px;
        padding-top: 10vh;
    }

    .launch__email__section2__image__text {
        left: 15px;
        right: 15px;
    }

    .launch__email__section3 p {
        max-width: 90%;
    }

    .launch__email__section3 {
        margin-top: 2vh;
    }

    .launch__email__section2__image__text {
        bottom: 10px;
    }

    .launch__email__section2__image__text p {
        font-size: 12px;
    }

    .launch__email__section2__title i {
        font-size: 30px !important;
        white-space: inherit !important;
    }

    .launch__email__section3 i {
        font-size: 30px !important;
    }

    .sectiontitle2__title {
        line-height: 2rem;
    }

    .launch__email__section3 p {
        font-size: 14px;
    }

    .launch__email__section3 {
        gap: 2vh;
    }

    .launch__email__section1__cta p {
        font-size: 40px !important;
    }
}