.divider__container {
    height: 0px;
    width: 100%;
    position: relative;
    z-index: 899;
    align-items: center;
}

.divider__wrapper {
    max-width: 600px;
    margin: 0 auto 0 auto;
    height: 0;
}

.divider__image {
    width: 100%;
    margin-top: -37px;
}

@media screen and (max-width: 960px) {

}
  
@media screen and (max-width: 768px) {
    .divider__image {
        margin-top: -26px;
    }
}