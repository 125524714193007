.section__event__container {
    position: relative;
    margin: 0;
    padding: 5vh 0 0vh 0;
    background-color: #fff;
    display: flex;
    gap: 10vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeIn .4s;
    -webkit-animation: fadeIn .4s;
    -moz-animation: fadeIn .4s;
    -o-animation: fadeIn .4s;
    -ms-animation: fadeIn .4s;
}

.section__event__wrapper {
    gap: 5vh;
    justify-content: center;
    max-width: 1080px;
    width: 90%;
    display: flex;
    flex-direction: column;
}

.section__event__kills, .section__event__friends {
    max-width: 90%;
}

.section__event__leaderboards {
    position: relative;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: 1600px;
    gap: 10vh;
    align-items: center;
}

.section__event__actual__progress__bar {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.section__event__actual__progress__bar p {
    position: absolute;
    font-weight: 600;
    color: rgb(255, 255, 255);
}

.section__event__actual__progress__bar img {
    position: absolute;
    right: 6%;
    width: 100px;
    top: -10px;
}

.section__event__progress__bar {
    width: 100%;
    padding: 5vh 0;
    max-width: 1400px;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5vh;
    background-size: 100% !important;
    background: url('/public/images/background-community.png');
    border: 2px solid aqua;
    z-index: 1;
}

.section__event__progress__bar p {
    color: rgb(255, 255, 255);
}


.section__envent__progress__bar__title {
    width: 100%;
    color: rgb(255, 255, 255);
    font-size: 35px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 2rem;
    font-family: 'Oswald', sans-serif;
}

.section__event__wrapper p {
    text-align: justify;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
    color: rgb(26, 25, 25);
}

.section__event__stars {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.starsleft-img {
    position: absolute;
    left: 0;
    max-height: 100%;
    z-index: 0;
}

.starsright-img {
    position: absolute;
    right: 0;
    max-height: 100%;
    z-index: 0;
}

.primaltransforms__primals__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 30px;
    flex-wrap: wrap;
}

.primaltransforms__primals__column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.primaltransforms__primals__column img {
    width: 150px;
}

.primaltransforms__primals__column p {
    font-size: 20px;
    font-weight: 600;
}

/* Small devices (phones) */
@media (max-width: 576px) {
    .section__event__container {
        gap: 5vh;
    }

    .section__event__wrapper {
        align-items: center;
    }

    .section__event__wrapper p {
        font-size: 15px;
        width: 95%;
    }

    .section__event__stars {
        display: none;
    }

    .section__event__progress__bar {
        background-size: cover !important;
        padding: 20px;
        gap: 0;
        width: 90%;
        box-sizing: border-box;
    }

    .section__event__actual__progress__bar p {
        top: -5px;
    }

    .section__event__leaderboards {
        gap: 5vh;
    }

    .section__event__actual__progress__bar img {
        position: absolute;
        right: 6%;
        width: 70px;
        top: 2px;
    }

    .section__envent__progress__bar__title {
        font-size: 25px;
    }

    .primaltransforms__primals__column img {
        width: 70px;
    }

    .primaltransforms__primals__column p {
       font-size: 15px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}