.section__hero__video__background__container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: fill;
}

.section__hero__video__background__player {
    width: 100%;
}

.section__hero__video__background__player {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

/* Small devices (phones) */
@media (max-width: 576px) {
    .section__hero__video__background__player {
        position: relative;
        width: auto;
        height: 100vh;
        transform: translateX(-50%);
        animation: fadeIn 1s;
        -webkit-animation: fadeIn 1s;
        -moz-animation: fadeIn 1s;
        -o-animation: fadeIn 1s;
        -ms-animation: fadeIn 1s;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}