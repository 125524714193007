.section__choose__loadout__container {
    position: relative;
    margin: 0;
    width: 100%;
    padding:0;
    background-size: cover !important;
    /* background-attachment: fixed !important; */
    background-repeat: no-repeat !important;
    background: url('/public/images/background-stars.jpg');
    display: flex;
    justify-content: center;
    animation: fadeIn .4s;
    -webkit-animation: fadeIn .4s;
    -moz-animation: fadeIn .4s;
    -o-animation: fadeIn .4s;
    -ms-animation: fadeIn .4s;
}

.section__choose__loadout__wrapper {
    padding: 10vh 0;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10vh;
    max-width: 1600px;
    align-items: center;
    justify-content: center;
    width: 100%;
}


.section__choose__loadout__slider {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10vw;
    margin-top: 10vh;
    min-height: 300px;
}

img.section__choose_loadout__slider__image__left {
    width: 400px;
    max-width: 90%;
}

.section__choose__loadout__slider__right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5vh;
}

.section__choose__loadout__slider__selector {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    justify-content: center;
}

.section__choose__loadout__slider__selector p {
    text-transform: uppercase;
    font-size: 20px !important;
}

.section__choose__loadout__slider__selector__category {
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.section__choose__loadout__slider__selector__active {
    height: 5px;
    background-color: #fa9800;
    margin-top: 5px;
}

.section__choose__loadout__slider__right h2 {
    width: 100%;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    text-align: left;
    line-height: 2rem;
    font-family: 'Oswald', sans-serif;
}

/* .section__choose__loadout__wrapper  p {
    font-weight: 600;
    font-size: 18px;
} */

.section__choose__loadout__slider__description {
    text-align: left;
    height: 100px;
    font-size: 18px;
    font-weight: 600;
}

.section__choose__loadout__slider__separator {
    height: 10px;
    background-color: #fa9800;
    width: 100%;
    margin-top: -20px;
}


.section__choose__loadout__slider__selector__category img {
    width: 60px;
    transition: all .3s ease-in-out;
}

.section__choose__loadout__slider__selector__category img:hover {
    transform: scale(1.07);
    transition: all .3s ease-in-out;
}

/* Small devices (phones) */
@media (max-width: 576px) {
    .section__choose__loadout__slider__right {
        width: 90%;
        align-items: center;
    }

    img.section__choose_loadout__slider__image__left {
        width: 250px;
    }

    .section__choose__loadout__wrapper {
        gap: 3vh
    }

    .section__choose__loadout__slider__selector {
        display: flex;
        justify-content: center;
        gap: 10px;
        width: 95%;
    }

    .section__choose__loadout__slider__description {
        text-align: justify;
        position: relative;
        margin-bottom: 40px;
        font-size: 16px;
    }
    
    .section__choose__loadout__slider__selector__category img {
        width: 40px;
    }

    .section__choose__loadout__slider__right h2 {
        font-size: 30px;
        text-align: center;
    }
    .section__choose__loadout__slider__selector p {
        text-transform: uppercase;
        font-size: 15px !important;
    }

    .section__choose__loadout__slider__separator {
        height: 5px;
        width: 50%;
    }

    .section__choose__loadout__slider {
        margin-top: inherit;
        gap: 3vh;
    }
}

.loader__cyl__container {
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}