.sectiontitle2__title {
    width: 100%;
    color: #fff;
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 3rem;
    font-family: 'Oswald', sans-serif;
}


/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
    .sectiontitle2__title {
        font-size: 30px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}