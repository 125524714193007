.section__news__container {
    position: relative;
    margin: 0;
    width: 100%;
    padding: 10vh 0;
    /* background-size: cover !important;
    background-repeat: no-repeat !important;
    background: url('/public/images/background-fog.png'); */
    /* background-attachment: fixed; */
    display: flex;
    justify-content: center;
    animation: fadeIn .4s;
    -webkit-animation: fadeIn .4s;
    -moz-animation: fadeIn .4s;
    -o-animation: fadeIn .4s;
    -ms-animation: fadeIn .4s;
}

.section__news__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10vh;
}

.section__news__boxes {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20vw;
    margin: 5vh 0;
    max-width: 1600px;
}

.section__bottom__container {
    width: 100%;
    position: absolute;
    bottom: -5px;
}

.section__bottom__container img {
    width: 100%;
    position: relative;
    object-fit: cover;
}

.section__news__post__box {
    width: 30%;
    height: 270px;
    position: relative;
    transition: all .3s ease-in-out !important;
    background-size: 100% !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.3) !important;
}

.frame {
    position: absolute;
    width: 740px;
    height: 420px;
}

.section__news__post__box:hover {
    background-size: 110% !important;
    border-radius: 10px;
    transition: all .3s ease-in-out;
}

.section__news__post__box__title {
    background-color: rgba(0, 0, 0, 0.329);
    position: absolute;
    bottom: 0;
}

.section__news__post__box__active {
    position: absolute;
    width: 700px;
    height: 380px;
    transition: all .3s ease-in-out;
    border-bottom: 10px solid #fa9800;
}

.section__news__post__box__active a {
    height: 100%;
    width: 100%;
    display: flex;
    background-size: 100% !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    transition: all .3s ease-in-out;

    animation: fadeIn .4s;
    text-decoration: none;
    -webkit-animation: fadeIn .4s;
    -moz-animation: fadeIn .4s;
    -o-animation: fadeIn .4s;
    -ms-animation: fadeIn .4s;
}

.section__news__box__text {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    max-width: 100%;    
}

.box__title {
    max-width: 100%;
    text-align: left;
    width: 100%;
    color: #fff;
    font-size: 35px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
    line-height: 3rem;
    font-family: 'Oswald', sans-serif;
}

.box__description {
    text-align: left;
}

.section__news__post__box__active:hover, .section__news__post__box__active a:hover {
    background-size: 105% !important;
}

.box__hidden {
    display: none;
}

.arrow__right {
    position: absolute;
    right: -10%;
}

.arrow__left {
    position: absolute;
    left: -10%;
}

.section__news__post__select__dots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 6vh 0;
    cursor: pointer;
}

/* Small devices (phones) */
@media (max-width: 576px) {
    .section__news__container {
        padding: 10vh 0;
        background-size: cover !important;
    }

    .section__news__wrapper {
        gap: 5vh;
    }
    
    .section__news__post__box {
        width: 90%;
    }

    .section__news__post__select__dots {
        gap: 5px;
        margin: 5vh 0;
    }

    .section__news__post__box__active {
        position: relative;
        height: 199px;
        max-width: 90%;
        border-radius: 0;
        border-bottom: 5px solid #fa9800;
    }

    .section__news__box__text {
        padding: 0 20px;;
    }

    .box__title {
        font-size: 17px;
        line-height: 1.5rem;
    }

    .box__description {
        font-size: 14px;
    }

    .section__news__post__box__active a {
        border-radius: 0;
    }

    .loader__container { 
        border-radius: 0;
    }

    .frame {
        display: none;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}