.animatedelipse__container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.animatedelipse__container img {
    position: absolute;
}

.animatedelipse__square {
    width: 25%;
}

.sharebuttons__animated__button, .animatedelipse__main, .animatedelipse__circles {
    width: 50px;
}