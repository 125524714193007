.section__compete__with__friends__container {
    position: relative;
    margin: 0;
    width: 100%;
    padding: 0;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex;
    height: 90vh;
    justify-content: center;
    animation: fadeIn .4s;
    -webkit-animation: fadeIn .4s;
    -moz-animation: fadeIn .4s;
    -o-animation: fadeIn .4s;
    -ms-animation: fadeIn .4s;
}

.section__compete__with__friends__container video {
    position: absolute;
    width: 100%;
    right: 0;
    top: 0;
    height: 100%;
    object-fit: cover;
}

.section__compete__with__friends__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10vh;
    width: 90%;
    height: 100%;
}

.section__compete__with__friends__description {
    justify-content: center;
    align-items: center;
    display: flex;
}

.section__compete__with__friends__description p {
    font-weight: 600;
    font-size: 20px;
    width: 50%;
}

.image-background {
    width: 100%;
    top: 0;
    left: 0%;
    position: absolute;
    object-fit: cover;
    height: 100%;
}

.image-team {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
}

.section__compete__with__your__friends__overlay {
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    background: rgb(63,94,251);
    background: linear-gradient(360deg, rgba(87, 87, 87, 0) 0%, #14269ac7 100%);
}

.section__compete__with__your__friends__cta {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

/* .section__compete__with__your__friends__cta p {
    font-size: 22px !important;
} */

/* Small devices (phones) */
@media (max-width: 576px) {
    .section__compete__with__friends__description p {
        width: 100%;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}