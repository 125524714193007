.cookiebanner__container {
    display: flex;
    width: 100%;
    flex-direction: row;
    background: url('/public/images/navbar-background.png');
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    margin: 0;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: #3aa4ef;
    bottom: 0;
    height: 50px;
    z-index: 999;
    padding: 10px 0;
}

.cookiebanner__buttons {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
}

.cookiebanner__wrapper {
    display: flex;
    width: 100%;
    border-top-right-radius: 5px;
    align-items: center;
    position: relative;
    justify-content: center;
}

.cookiebanner__text {
    position: absolute;
    left: 20px;
}

.cookiebanner__button {
    cursor: pointer;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border: 1px solid;
    border-radius: 3px;
}

.cookiebanner__button:hover {
    background-color: rgba(46, 46, 46, 0.24);
}

.accept {
    border-color: white;
}

.decline {
    border-color: white;
}

.functional {
    border-color: white;
}

.cookiebanner__consent__set {
    bottom: 0;
    width: 10%;
    height: 10px;
    background-color: aqua;
    background: url('/public/images/navbar-background.png');
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: fixed;
    cursor: pointer;
    z-index: 999;
}

.cookiebanner__consent__set:hover {
    height: 50px;
    transition-duration: .3s;
}

/* Small devices (phones) */
@media (max-width: 576px) {
    .cookiebanner__text {
        position: relative;
        left: inherit;
    }

    .cookiebanner__wrapper {
        flex-direction: column;
        width: 90%;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .cookiebanner__container {
        padding: 0 0 30px 0;
        height: 120px;
        background-size: cover;
    }

    .cookiebanner__buttons {
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        right: inherit;
    }

    .cookiebanner__buttons p {
        font-size: 14px;
    }

    .cookiebanner__consent__set {
        width: 25%;
        height: 30px;
    }
}