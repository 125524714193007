.footer__container {
    width: 100%;
    background-color: #212944;
}

.footer__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3vh 0;
    gap:2vh;
}

.semc-logo {
    width: 200px;
}

.footer__social__links {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.footer__container p, .footer__container a {
    padding: 0;
    margin: 0;
}