.section__what__is__cb__container {
    position: relative;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    animation: fadeIn .4s;
    -webkit-animation: fadeIn .4s;
    -moz-animation: fadeIn .4s;
    -o-animation: fadeIn .4s;
    -ms-animation: fadeIn .4s;
    padding-top: 15vh;
}

.section__what__is__cb__video__player {
    position: relative;
    padding-top: 56.25%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.section__what__is__cb__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 7vh;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 10vh;
}

.section__what__is__cb__heroes__bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 100%;
}

.loader__container__what__is__cb {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


.section__what__is__cb__wideo__slider__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2vh;
    padding: 10px;
    max-width: 90%;
}

.section__what__is__cb__heroes__bottom__female img, .section__what__is__cb__heroes__bottom__male img {
    position: absolute;
    bottom: 10vh;
    width: 30%;
}

.section__what__is__cb__heroes__bottom__male img {
    left: 0;
}

.section__what__is__cb__heroes__bottom__female img {
    right: 0;
}

.section__what__is__cb__heroes__background__behind {
    position: absolute;
    width: 100%;
    right: 0;
    bottom: 0;
}

.section__what__is__cb__heroes__background__above {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background-size: 100% !important;
    background: url('/public/images/hero-background-above.png');
    background-repeat: no-repeat;
    background-position-y: bottom;
}

.section__what__is__cb__heroes__background__above img, .section__what__is__cb__heroes__background__behind img {
    width: 100%;
}

.section__what__is__cb__slide__bullets {
    display: flex;
    flex-direction: row;
    gap: 20px;
    z-index: 1;
    margin: 0 0 20px 0;
}

.section__what__is__cb__slide__bullet__selector {
    cursor: pointer;
}

.section__what__is__cb__slide__active__texts {
    width: 600px;
    max-width: 90%;
    margin: 0;
    position: relative;
}

.section__what__is__cb__slide__active__texts h2 {
    max-width: 100%;
    text-align: center;
    width: 100%;
    color: rgb(26, 25, 25);
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    line-height: 3rem;
    font-family: 'Oswald', sans-serif;
}

.section__what__is__cb__slide__active__texts p {
    text-align: justify;
    font-weight: 600;
    width: 100%;
    color: rgb(26, 25, 25);
    font-size: 20px;
}


/* Small devices (phones) */
@media (max-width: 576px) {
    .section__what__is__cb__slide__active__texts h2 {
        color: #fff;
    }

    .section__what__is__cb__slide__active__texts p {
        font-size: 15px;
        color: #fff;
        min-height: 100px;
    }

    .section__what__is__cb__heroes__bottom__female, .section__what__is__cb__heroes__bottom__male {
        display: none;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}