.arrow__slider__container {
    position: relative;
    width: 116px;
    height: 71px;
    padding: 0;
    cursor: pointer;
}

.arrow__slider__arrow {
    position: absolute;
}

.arrow__slider__dot {
    position: absolute;
    bottom: 26%;
    right: 0;
}