.sharebuttons__container {
    position: fixed;
    bottom: 40px;
    right: 0px;
    width: 200px;
    height: 70px;
    background: url('/public/images/navbar-background.png');
    background-size: cover;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 15px;
    gap: 20px;
    transform: translateX( 150px );
    transition: all .4s ease-in-out;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.sharebuttons__container p {
    width: 100%;
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 2rem;
    font-family: 'Oswald', sans-serif;
    text-shadow: 2px 2px 0px #3b3b3b;
}

.sharebuttons__container img {    
    cursor: pointer;
}

.sharebuttons__active {
    transform: translateX( 0 );
    height: fit-content;
    padding: 20px
}

.sharebuttons__separator {
    width: 3px;
    height: 150px;
    background-color: aqua;
}

.sharebuttons__vertical__separator {
    width: 80%;
    height: 3px;
    background-color: aqua;
    margin: 20px 0;
}


.sharebuttons__social__icons {
    height: 100%;
    width: 100%;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.sharebuttons__close__button {
    position: absolute;
    width: 20px;
    top: 10px;
    left: 10px;
}

.sharebuttons__socialhub {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}


.sharebuttons__social__icons img, .sharebuttons__socialhub__join__community img {
    width: 50px;
    transition: all .2s ease-in-out;
}

.sharebuttons__social__icons img:hover, .sharebuttons__socialhub__join__community img:hover {
    transform: scale(1.05);
}

/* Small devices (phones) */
@media (max-width: 576px) {
    .sharebuttons__separator {
        height: 150px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
