.App {
  text-align: center;
  overflow-x: hidden;
  max-width: 100%;
  position: relative;
  outline: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

p {
  color: #fff;
  position: relative;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  outline: none;
}

a {
  color: white;
  text-decoration: underline;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

a:hover {
  color:#f7bb38;
}


h1 {
  font-size: 50px;
  color: #fff;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  line-height: 2rem;
  font-family: 'Oswald', sans-serif;
}

img {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  outline: none;
}

.hidden {
  opacity: 0;
}

.App-link {
  color: #61dafb;
}

.fade-in {
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  -moz-animation: fadeIn .5s;
  -o-animation: fadeIn .5s;
  -ms-animation: fadeIn .5s;
}

.fade-in-from-bottom {
  animation: fadeInFromBottom .8s;
  -webkit-animation: fadeInFromBottom .8s;
  -moz-animation: fadeInFromBottom .8s;
  -o-animation: fadeInFromBottom .8s;
  -ms-animation: fadeInFromBottom .8s;
}

.fade-in-from-left {
  animation: fadeInFromLeft .7s;
  -webkit-animation: fadeInFromLeft .7s;
  -moz-animation: fadeInFromLeft .7s;
  -o-animation: fadeInFromLeft .7s;
  -ms-animation: fadeInFromLeft .7s;
}

.fade-in-from-right {
  animation: fadeInFromRight .7s;
  -webkit-animation: fadeInFromRight .7s;
  -moz-animation: fadeInFromRight .7s;
  -o-animation: fadeInFromRight .7s;
  -ms-animation: fadeInFromRight .7s;
}

.fade-in-left {
  animation: fadeInLeft .7s;
  -webkit-animation: fadeInLeft .7s;
  -moz-animation: fadeInLeft .7s;
  -o-animation: fadeInLeft .7s;
  -ms-animation: fadeInLeft .7s;
}

.fade-in-right {
  animation: fadeInRight .7s;
  -webkit-animation: fadeInRight .7s;
  -moz-animation: fadeInRight .7s;
  -o-animation: fadeInRight .7s;
  -ms-animation: fadeInRight .7s;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.363);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  outline: none;
}

.nodrag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  outline: none;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  h1 {
    font-size: 25px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeInFromBottom {
  0% { transform: translateY(100px); }
  100% { transform: translateY(0); }
}

@keyframes fadeInFromLeft {
  0% { opacity: 1; transform: translateX(-1200px); }
  100% { opacity: 0; transform: translateX(0); }
}

@keyframes fadeInFromRight {
  0% { opacity: 1; transform: translateX(1200px); }
  100% { opacity: 0; transform: translateX(0); }
}

@keyframes fadeInLeft {
  0% { opacity: 1; transform: translateX(0); }
  100% { opacity: 0; transform: translateX(-1200px); }
}

@keyframes fadeInRight {
  0% { opacity: 1; transform: translateX(0); }
  100% { opacity: 0; transform: translateX(1200px); }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes moveInHugeLeft {
  0% {
      opacity: 0;
      transform: translateX(-40px);
  }

  100% {
      opacity: 1;
      transform: translateX(0px);
  }
}


@keyframes moveInHugeBottom {
  0% {
      opacity: 0;
      transform: translateY(40px);
  }

  100% {
      opacity: 1;
      transform: translateY(0px);
  }
}